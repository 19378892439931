import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';

const YakBakPrivacyPolicy = () => {
    return (
        <>
            <SEO title="Yak Bak's Privacy Policy" />
            <ColorSwitcher />
            <main className="main-wrapper">
                {/* <HeaderOne /> */}
                <BreadCrumbOne
                    title="Yak Bak's Privacy Policy"
                    page="Yak Bak's Privacy Policy"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <div className="section-title">
                                        <h5 className="title">This Privacy policy was published on August 21th, 2023.</h5>
                                    </div>
                                    <h4>Introduction</h4>
                                    <p>Welcome to the privacy policy of Yak Bak! 90's Toy Voice Mod (“we”, “our”, or “us”). We are committed to respecting your privacy. This policy outlines our practices regarding the collection, use, and sharing of your personal information.</p>
                                    <h4>Scope</h4>
                                    <p>This privacy policy applies solely to the Yak Bak! 90's Toy Voice Mod mobile application.</p>
                                    <h4>Data We Collect</h4>
                                    <p>Considering the functionality of our app, we do NOT collect or store personal information or voice recordings from our users.</p>
                                    <h4>How We Use Your Data</h4>
                                    <p>We use access to your device's microphone solely for the purpose of the app's primary functionality - to record and modify voices in real-time. These voice recordings are not stored, shared, or used outside of the immediate context of the app.</p>
                                    <h4>Children's Privacy</h4>
                                    <p>Our app is designed for users of all ages, including children under the age of 13. We do not knowingly collect personal data from children or other users. As our app doesn't collect any data, there is no risk of children's data being mishandled.</p>
                                    <h4>Data Security</h4>
                                    <p>Given that we do not collect or store any data, there is no user data at risk. However, we value your trust in using our app, so we are always striving to use acceptable means of protecting it.</p>
                                    <h4>Changes to This Privacy Policy</h4>
                                    <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify users of any changes by posting the new Privacy Policy on this page. The changes are effective immediately after they are posted.</p>
                                    <h4>Contact Us</h4>
                                    <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a href="mailto:grisuceo@gmail.com">grisuceo@gmail.com</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <CtaLayoutOne /> */}
                {/* <FooterOne parentClass="" /> */}
            </main>
        </>
    )
}

export default YakBakPrivacyPolicy;